import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import {
  BlogList,
  BgImage,
  Container,
  Layout,
  PaginationLinks,
  Section,
} from "../components"
import SEO from "../components/seo"

export default function PostList(props) {
  const { edges: posts } = props.data.allMdx
  const { currentPage, numberOfPages } = props.pageContext

  return (
    <Layout>
      <SEO title="Notes" keywords={[`gatsby`, `noah wong`, `blog`]} />
      <Section>
        <Container>
          <h2
            css={`
              margin-bottom: 0;
            `}
          >
            Latest Thoughts
          </h2>
          <div
            css={`
              margin: 0 -2rem;
              min-height: 600px;
            `}
          >
            <BlogList posts={posts} />
          </div>
          <PaginationLinks
            currentPage={currentPage}
            numberOfPages={numberOfPages}
          />
        </Container>
      </Section>
      <BgImage
        fluid={props.data.desktop.childImageSharp.fluid}
        height="50vh"
        mobileHeight="50vh"
        title="desktop"
        overlayColor="rgba(0, 0, 0, 0.3)"
      />
    </Layout>
  )
}

const ViewTags = styled(Link)`
  color: var(--gray-700);
  font-size: 1.2rem;
  text-decoration: none;
`

export const postListQuery = graphql`
  query postListQuery($skip: Int!, $limit: Int!) {
    desktop: file(relativePath: { eq: "building.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 300)
          frontmatter {
            author
            date
            length
            location
            path
            tags
            title
          }
        }
      }
    }
  }
`
